import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import React from 'react';
import RedirectOs from './helper/RedirectOs';
import Main from './app/Main'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/os" element={<RedirectOs />} />
        <Route exact path="/" element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
